import React from "react";
import { Link } from "react-router-dom";
import facebook from "../../assets/img/social/facebook.png";
import whatsapp from "../../assets/img/social/whatsapp.png";
import instagram from "../../assets/img/social/instagram.png";
import ReactWhatsapp from "react-whatsapp";
const socialMediaUrl = [
  {
    id: "2",
    imgurl: instagram,
    sociallink: "https://www.instagram.com/mamastycoon/",
  },
  {
    id: "2",
    imgurl: facebook,
    sociallink: "https://www.facebook.com/mammastycoon",
  },
];
function SocialMedia() {
  const obj = {
    id: "1",
    imgurl: whatsapp,
    sociallink:
      "https://api.whatsapp.com/send/?phone=9117965342-&text&type=phone_number&app_absent=0",
  };
  return (
    <div className="position-fixed z-9 top-50">
      <div
        style={{
          border: "solid 0.02rem #dec1c1",
          borderRadius: "5px 0 5px 0",
          margin: "2px 0",
        }}
      >
        <div className="px-1 py-1">
          <ReactWhatsapp
            style={{
              border: "none",
              backgroundColor: "transparent",
              padding: "0",
            }}
            number="+97 466362210"
            massage="Hello Mamass Tycoon"
          >
            {" "}
            <img src={obj.imgurl} alt="socialLink" width={30} />
          </ReactWhatsapp>
          {/* <Link to={item?.sociallink} target='_blank'>
                            <img src={item.imgurl} alt='socialLink' width={30} />
                        </Link> */}
        </div>
      </div>
      {socialMediaUrl.map((item, key) => {
        return (
          <div
            key={key}
            style={{
              border: "solid 0.02rem #dec1c1",
              borderRadius: "5px 0 5px 0",
              margin: "2px 0",
            }}
          >
            <div className="px-1 py-1">
              <a href={item?.sociallink} target="_blank">
                <img src={item.imgurl} alt="socialLink" width={30} />
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SocialMedia;
